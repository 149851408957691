<template>
  <div class="ActivityCenter">
    <div class="topbar">
      <v-icon size="30" @click="$router.push('/')" color="#000"
        >mdi-arrow-left</v-icon
      >

      <div class="head-title">{{ $t("活動中心") }}</div>
    </div>

    <div class="content">
      <ul>
        <li v-for="item in bannerList" :key="item.id" @click="linkTo(item)">
          <div class="activity-img">
            <img :src="item.url_pc" alt="" />
          </div>
          <div class="activity-content">
            <div class="activity-content-left">
              <div class="sp1">
                {{ item.url_type ? "官網直充加贈點數" : "G18大禮包特權碼" }}
              </div>
              <div class="sp2">
                {{ item.url_type ? "活动时间7/8-7/21" : "請在遊戲內輸入" }}
              </div>
            </div>
            <div v-if="item.url_type" class="activity-content-right">
              {{ "立即充值" }}
            </div>
            <div v-else></div>
          </div>
        </li>
      </ul>
    </div>
    <Dialog ref="download" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      bannerList: [],
    };
  },
  created() {
    this.getHomeData();
  },
  methods: {
    linkTo(item) {
      if (item.url_type) {
        this.getViewCount(3004, item.id);
        this.$router.push(item.url);
      } else {
        this.$refs.download.dialog2 = true;
        this.$refs.download.text1 = "温馨提示";
        this.$refs.download.text2 = "請下載該遊戲、於遊戲內兌換！";
        this.getViewCount(3004, item.id);
      }
    },
    // 获取首页数据
    getHomeData() {
      this.$get("/api/Home/Data").then((res) => {
        // this.bannerList = res.data.banners;
        res.data.banners.map((item) => {
          if (item.url_type) {
            this.bannerList.push(item);
          }
          if (item.banner_type == 1) {
            this.bannerList.push(item);
          }
        });
      });
      console.log(this.bannerList);
    },
    // 获取点击浏览记录
    getViewCount(code, id) {
      this.$post("/api/Home/G18ClickDown", {
        code: code,
        game_id: id,
      }).then((res) => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.ActivityCenter {
  padding-top: 46px;
  .topbar {
    background-color: #fff;
    height: 46px;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 100;
    .v-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 24px;
    }
    .head-title {
      position: absolute;
      line-height: 1;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
      left: 50%;
      font-size: 18px;
      color: #000;
    }
  }
  .content {
    padding-top: 30px;
    ul {
      padding: 0 24px 0 24px;
      display: flex;
      align-items: center;
      flex-direction: column;
      li {
        background: #ffffff;
        box-shadow: 0px 20px 20px rgba(230, 236, 234, 0.3);
        margin-bottom: 20px;
        .activity-img {
          img {
            display: block;
            width: 100%;
          }
        }
        .activity-content {
          padding: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .activity-content-left {
            text-align: left;
            .sp1 {
              font-size: 14px;
              color: #333333;
            }
            .sp2 {
              font-size: 12px;
              color: #22c907;
            }
          }
          .activity-content-right {
            padding: 5px 15px;
            font-size: 12px;
            color: #fff;
            background: #f56490;
            border-radius: 20px;
          }
        }
      }
    }
  }
}
</style>